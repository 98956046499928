import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { TICKET_SERVICE_CONFIG } from '../config/ticket-service.config.interface';
import { ApiTicket, Ticket } from '../state/tickets/tickets.models';

@Injectable({ providedIn: 'root' })
export class TicketService {
  private config = inject(TICKET_SERVICE_CONFIG);
  private http = inject(HttpClient);

  loadTickets(fairId: string): Observable<Ticket[]> {
    return this.http
      .get<ApiTicket[]>(`${this.config.ticketServiceApiUrl}/fairs/${fairId}`)
      .pipe(map((response) => response.map(this.mapTicketToFrontend)));
  }

  mapTicketToFrontend(apiTicket: ApiTicket): Ticket {
    return {
      id: apiTicket.id,
      iframeUrl: 'https://tickets.messe-muenchen.de/MM-TTS/P3Q9MDk5OTAxMTA1MDAwMDAyMCZzaD10QXZQVm8mY3Q9aHRtbA==',
      article_name: {
        de: apiTicket.article_name.de,
        en: apiTicket.article_name.en,
      },
    };
  }
}
